<template>
  <section class="banner">
    <!-- Banner items -->
    <div class="banner__list-container">
      <div class="banner__list" :style="`transform: translateX(-${bannerPos}%)`">
        <template v-for="(banner, i) in items">
          <banner
            v-if="banner && banner.cover_image"
            :key="`Banner-${banner.cover_image}`"
            source="home"
            :banner="banner"
            :observer="observerObj"
            :is-interact="isInteraction(banner.cover_image)"
            :position="i"
            :session-id="sessionId"
          />
        </template>
      </div>
      <span class="banner__list-spacer" />
    </div>

    <!-- Banner item indicators -->
    <div class="banner__indicators">
      <div
        v-for="(banner, i) in items"
        :key="`BannerIndicators-${banner.cover_image}`"
        class="banner__indicators-item"
        :class="{ 'banner__indicators-item--active': isActive(i) }"
      />
    </div>

    <!-- Navigation icons -->
    <button
      class="banner__btn banner__btn--left"
      tabindex="0"
      title="Previous"
      :class="{ 'banner__btn--hidden': isFirst }"
      @click="onPrevious"
    >
      <span class="banner__btn-bg" />
      <svg-arrow class="banner__btn-icon" />
    </button>
    <button
      class="banner__btn banner__btn--right"
      tabindex="0"
      title="Next"
      :class="{ 'banner__btn--hidden': isLast }"
      @click="onNext"
    >
      <span class="banner__btn-bg" />
      <svg-arrow class="banner__btn-icon" />
    </button>
  </section>
</template>

<script>
import Banner from './Banner'
import ImpressionObserverMixin from '../../mixins/tracking/impressionObserver'
import SvgArrowLeft from '../../assets/svg/arrow_left.svg?inline'

export default {
  components: {
    banner: Banner,
    'svg-arrow': SvgArrowLeft,
  },
  computed: {
    bannerPos() {
      return 100 * this.currentIndex
    },
    isFirst() {
      return this.currentIndex === 0
    },
    isLast() {
      return this.currentIndex === this.items.length - 1
    },
  },
  data() {
    return {
      currentIndex: 0,
      ready: false,
      timer: null,
    }
  },
  methods: {
    backToStart() {
      this.currentIndex = 0
      this.startTimer()
    },
    handleLooping() {
      if (this.isLast) {
        this.backToStart()
      } else {
        this.onNext()
      }
    },
    isActive(i) {
      return this.currentIndex === i
    },
    onNext() {
      if (!this.isLast) {
        this.currentIndex++
      }
      this.startTimer()
    },
    onPrevious() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
      this.startTimer()
    },
    startTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(this.handleLooping, 3000)
    },
  },
  mixins: [ImpressionObserverMixin],
  mounted() {
    this.startTimer()
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    sessionId: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.banner {
  @apply flex flex-col items-center;
  @apply relative w-full;
}

/* Banner items */
.banner__list-container {
  @apply relative w-full overflow-x-hidden;
  max-width: 720px;

  & .banner__list {
    @apply flex absolute inset-y-0 z-1;
    transition: transform 0.3s ease;
  }
  & .banner__list-spacer {
    @apply block w-full z-0;
    padding-top: 52.33333%; /* 628px / 1200px */
  }
}

/* Banner item indicators */
.banner__indicators {
  @apply flex mt-5 items-center;
}
.banner__indicators-item {
  @apply rounded-lg mx-1 bg-grey-200;
  width: 16px;
  height: 4px;

  &.banner__indicators-item--active {
    @apply bg-grey-600;
  }
}

/* Navigation buttons */
.banner__btn {
  @apply flex items-center justify-center;
  @apply absolute rounded-full z-1;

  & .banner__btn-icon {
    @apply absolute;
    width: 28px;
    height: 17px;
  }
  & .banner__btn-bg {
    @apply absolute inset-0 rounded-full;
    @apply bg-pink-light z-0;
    opacity: 0.6;
  }

  &.banner__btn--right .banner__btn-icon {
    transform: rotate(180deg);
  }
  &.banner__btn--hidden {
    @apply hidden;
  }
  &:focus {
    @apply outline-none;
  }
}

@screen mobile {
  .banner {
    @apply px-2;
  }

  /* Navigation buttons */
  .banner__btn {
    top: calc(50% - 28px);
    width: 32px;
    height: 32px;

    &.banner__btn--left {
      @apply left-0;
    }
    &.banner__btn--right {
      @apply right-0;
    }
    &:active .banner__btn-bg {
      opacity: 0.8;
    }
  }
}

@screen desktop {
  /* Navigation buttons */
  .banner__btn {
    top: calc(50% - 40px);
    width: 56px;
    height: 56px;

    &.banner__btn--left {
      left: 24px;
    }
    &.banner__btn--right {
      right: 24px;
    }
    &:hover .banner__btn-bg {
      opacity: 0.8;
    }
  }
}
</style>
