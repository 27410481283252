<template>
  <div class="home-job-cate-menu">
    <template v-for="key in mobileJobCateItems">
      <home-job-cate-menu-item :key="`CategoryButton-${key}`" :category-key="key" :event-source="eventSource" />
    </template>
  </div>
</template>

<script>
import HomeJobCateMenuItem from './homeJobCateMenuItem'
import HomeMixin from '../../mixins/home'

export default {
  components: {
    'home-job-cate-menu-item': HomeJobCateMenuItem,
  },
  mixins: [HomeMixin],
  props: {
    /**
     * Used for event tracking
     */
    eventSource: {
      default: '',
      type: String,
    },
  },
}
</script>

<style scoped>
.home-job-cate-menu {
  @apply flex flex-col flex-wrap;
  @apply overflow-x-scroll pt-1;
  height: 280px;
}
</style>
